import React, { FC, useMemo } from "react";
import {
  Avatar,
  Col,
  Divider,
  Grid,
  Row,
  Skeleton,
  Space,
  Tag,
  Typography,
} from "antd";
import {
  useOrganizationDetails,
  useOrganizationOverview,
  useOrganizationUsers,
  useOrganizationWorkspaceSections,
} from "@dewo/app/containers/organization/hooks";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import { OrganizationInviteButton } from "@dewo/app/containers/invite/OrganizationInviteButton";
import _ from "lodash";
import { DebugMenu } from "@dewo/app/components/DebugMenu";
import { TaskDiscoveryList } from "../../../components/task/TaskDiscoveryList";
import { OrganizationPublicInviteButton } from "../../invite/OrganizationPublicInviteButton";
import { OrganizationHeaderSummary } from "./OrganizationHeaderSummary";
import { RichMarkdownViewer } from "@dewo/app/components/richMarkdown/RichMarkdownViewer";
import { DiscordRoleGateAlert } from "../../invite/DiscordRoleGateAlert";
import { JoinTokenGatedWorkspacesAlert } from "../../invite/JoinTokenGatedWorkspaceAlert";
import { OverviewWorkspaceSection } from "./workspaces/OverviewWorkspaceSection";
import { CreateOrImportWorkspaceButton } from "../../workspace/create/CreateWorkspaceButton";
import { Can } from "@dewo/app/contexts/PermissionsContext";
import { PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { WorkspaceSectionOptionsButton } from "./workspaces/WorkspaceSectionOptionsButton";

interface Props {
  organizationId: string | undefined;
}

export const OrganizationOverview: FC<Props> = ({ organizationId }) => {
  const { organization } = useOrganizationDetails(organizationId);
  const overview = useOrganizationOverview(organizationId);
  const { admins } = useOrganizationUsers(organizationId);
  const sections = useOrganizationWorkspaceSections(organizationId);

  const contributors = useMemo(
    () =>
      _.uniqBy(
        overview?.node.workspaceChildren
          .map((e) => e.node.workspace?.contributors ?? [])
          .flat(),
        "id"
      ),
    [overview?.node.workspaceChildren]
  );

  const hasOpenTasks = useMemo(
    () =>
      !!overview?.node.workspaceChildren.some(
        (e) => !!e.node.workspace?.openTaskCount
      ),
    [overview?.node.workspaceChildren]
  );

  const screens = Grid.useBreakpoint();
  if (_.isEmpty(screens)) return null;
  return (
    <>
      <OrganizationHeaderSummary organizationId={organizationId} />
      <Divider />
      <Row
        gutter={[screens.xs ? 0 : 48, 24]}
        style={{ marginTop: 20 }}
        className="max-w-xxl w-full dewo-layout-padding-vertical"
      >
        <Col
          xs={24}
          lg={18}
          className={!screens.xs ? "dewo-divider-right" : undefined}
        >
          <Skeleton active loading={!organizationId || !sections}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <DiscordRoleGateAlert organizationId={organizationId!} />
              <JoinTokenGatedWorkspacesAlert organizationId={organizationId!} />
              {sections?.map((section, index) => (
                <OverviewWorkspaceSection
                  key={section.id}
                  title={section.name}
                  workspaces={section.workspaces}
                  layout={section.layout}
                  extra={[
                    !section.default && (
                      <Can key="options" I="update" this={section}>
                        <WorkspaceSectionOptionsButton
                          section={section}
                          organizationId={section.organizationId}
                        />
                      </Can>
                    ),
                    <div key="flex" style={{ flex: 1 }} />,
                    index === 0 && (
                      <Can
                        key="create"
                        I="create"
                        this={{
                          __typename: "Workspace",
                          organizationId: organizationId!,
                        }}
                      >
                        <CreateOrImportWorkspaceButton
                          organizationId={organizationId!}
                          type="primary"
                          size="small"
                          icon={<PlusOutlined />}
                          children="Create"
                        />
                      </Can>
                    ),
                  ]}
                />
              ))}
              {hasOpenTasks && (
                <TaskDiscoveryList organizationId={organizationId} />
              )}
            </Space>
          </Skeleton>
        </Col>
        <Col xs={24} lg={6}>
          <Typography.Title level={5}>About</Typography.Title>
          <div style={{ opacity: 0.7 }}>
            <RichMarkdownViewer
              value={organization?.description || "No description..."}
            />
          </div>

          {!!organization?.tags.length && (
            <Row style={{ gap: 8, marginTop: 16 }}>
              {organization?.tags.map((tag) => (
                <Tag key={tag.id} color={tag.color} style={{ margin: 0 }}>
                  {tag.label}
                </Tag>
              ))}
            </Row>
          )}

          <Divider />

          <Typography.Title level={5}>Contributors</Typography.Title>
          <Row style={{ marginBottom: 16 }}>
            <Avatar.Group maxCount={6} size="large">
              {!contributors &&
                _.range(3).map((i) => <Skeleton.Avatar size="large" key={i} />)}
              {contributors?.map((user) => (
                <UserAvatar key={user.id} user={user} linkToProfile />
              ))}
            </Avatar.Group>
          </Row>
          {!!organizationId ? (
            <OrganizationPublicInviteButton organizationId={organizationId} />
          ) : (
            <Skeleton.Button active />
          )}

          <Divider />

          <Typography.Title level={5}>Admins</Typography.Title>
          <Row style={{ marginBottom: 16 }}>
            <Avatar.Group maxCount={3} size="large">
              {!admins &&
                _.range(3).map((i) => <Skeleton.Avatar size="large" key={i} />)}
              {admins?.map((user) => (
                <UserAvatar key={user.id} user={user} linkToProfile />
              ))}
            </Avatar.Group>
          </Row>

          <Row style={{ rowGap: 8, columnGap: 8 }}>
            {!!organizationId ? (
              <OrganizationInviteButton
                organizationId={organizationId}
                icon={<UsergroupAddOutlined />}
              />
            ) : (
              <Skeleton.Button active />
            )}
            <DebugMenu organizationId={organizationId} />
          </Row>
        </Col>
      </Row>
    </>
  );
};
