import React, { FC, useMemo } from "react";
import { Button, Row, Skeleton, Space, Tag, Typography } from "antd";
import { OrganizationAvatar } from "@dewo/app/components/avatars/OrganizationAvatar";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import Link from "next/link";
import { useOrganization, useOrganizationDetails } from "../hooks";
import { useOrganizationRoles } from "../../rbac/hooks";
import { RulePermission } from "@dewo/app/graphql/types";
import { EntityDetailAvatar } from "@dewo/app/components/avatars/EntityDetailAvatar";
import { FollowGraphNodeButton } from "../../graph/buttons/FollowGraphNodeButton";

import { ClockCircleFilled, DollarCircleFilled } from "@ant-design/icons";
import * as Colors from "@ant-design/colors";
import moment from "moment";

interface Props {
  organizationId: string | undefined;
}

const OrganizationPaymentSummary: FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { organization } = useOrganizationDetails(organizationId);
  const daysToPayment = useMemo(
    () =>
      organization &&
      moment.duration(organization.timeToPayment).asDays().toFixed(1),
    [organization]
  );

  return (
    <Space align="center" style={{ paddingTop: 4 }}>
      {!!daysToPayment && (
        <Tag style={{ background: Colors.grey[6] }}>
          <ClockCircleFilled />
          <Typography.Text>
            <Typography.Text strong>Time to payment: </Typography.Text>
            {daysToPayment ? `${daysToPayment} days` : "N/A"}
          </Typography.Text>
        </Tag>
      )}
      {!!organization?.totalPaid && (
        <Tag style={{ background: Colors.grey[6] }}>
          <DollarCircleFilled />
          <Typography.Text>
            <Typography.Text strong>Total paid: </Typography.Text> $
            {organization?.totalPaid.toFixed(2)}
          </Typography.Text>
        </Tag>
      )}
    </Space>
  );
};

export const OrganizationHeaderSummary: FC<Props> = ({ organizationId }) => {
  const details = useOrganizationDetails(organizationId).organization?.details;
  const organization = useOrganization(organizationId);
  const canUpdate = usePermission("update", organization);
  const canManagePermissions = usePermission("create", {
    __typename: "Rule",
    permission: RulePermission.MANAGE_ORGANIZATION,
  });
  const roles = useOrganizationRoles(organizationId);

  const showSetupProfile = !!organization && !organization.tagline && canUpdate;
  const showSetupPermissions = useMemo(() => {
    if (!roles || !canManagePermissions) return false;
    const rolesWithPermissions = roles.filter(
      (r) => !r.fallback && !!r.rules.length
    );
    const hasSetupPermissionsForMoreThanOrgCreator =
      rolesWithPermissions.length > 1;
    return !hasSetupPermissionsForMoreThanOrgCreator;
  }, [roles, canManagePermissions]);

  const content = (() => {
    if (!organization) {
      return (
        <>
          <Skeleton.Avatar active size={96} />
          <Skeleton
            loading
            active
            paragraph={{ rows: 1 }}
            style={{ width: 200 }}
          />
        </>
      );
    }

    return (
      <>
        <OrganizationAvatar organization={organization} size={96} />
        <div style={{ flex: 1 }}>
          <Typography.Title level={1} style={{ fontSize: 30, marginBottom: 0 }}>
            {organization.name}
          </Typography.Title>
          {!!organization?.tagline && (
            <Typography.Paragraph type="secondary" style={{ maxWidth: 480 }}>
              {organization.tagline}
            </Typography.Paragraph>
          )}

          {(showSetupProfile || showSetupPermissions) && (
            <Row style={{ columnGap: 8 }}>
              {showSetupProfile && (
                <Link href={`${organization.permalink}/settings/profile`}>
                  <a>
                    <Button
                      size="small"
                      type="primary"
                      name="Setup DAO Profile from overview header"
                    >
                      Setup profile
                    </Button>
                  </a>
                </Link>
              )}
              {showSetupPermissions && (
                <Link href={`${organization.permalink}/settings/access`}>
                  <a>
                    <Button
                      size="small"
                      name="Setup DAO permissions from overview header"
                    >
                      Setup permissions
                    </Button>
                  </a>
                </Link>
              )}
            </Row>
          )}

          <Row style={{ gap: 8, marginTop: 8 }} align="middle">
            {!!organizationId && (
              <OrganizationPaymentSummary organizationId={organizationId} />
            )}

            {!!details &&
              details.map((detail) => (
                <EntityDetailAvatar entityDetail={detail} key={detail.id} />
              ))}
          </Row>
        </div>
        <FollowGraphNodeButton nodeId={organization.nodeId} showUnfollow />
      </>
    );
  })();

  return (
    <Row
      style={{ columnGap: 16, rowGap: 8, paddingBottom: 8 }}
      className="dewo-layout-padding-vertical"
    >
      {content}
    </Row>
  );
};
