import React from "react";
import { useOrganizationFromRouterQuery } from "@dewo/app/containers/organization/hooks";
import { OrganizationSeo } from "@dewo/app/containers/seo/OrganizationSeo";
import { NextPage } from "next";
import { OrganizationOverview } from "@dewo/app/containers/organization/overview/OrganizationOverview";
import { Header } from "@dewo/app/containers/navigation/header/Header";

const Page: NextPage = () => {
  const organization = useOrganizationFromRouterQuery();
  return (
    <>
      <Header />
      <OrganizationOverview organizationId={organization?.id} />
      <OrganizationSeo />
    </>
  );
};

export default Page;
