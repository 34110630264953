import React, { FC } from "react";
import { Avatar } from "antd";

import { DiscordIcon } from "@dewo/app/components/icons/Discord";
import { EntityDetail, EntityDetailType } from "../../graphql/types";
import { useCopyToClipboardAndShowToast } from "../../util/hooks";
import {
  EnvironmentOutlined,
  GithubOutlined,
  LinkedinFilled,
  LinkOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

export const placeholderByType: Record<EntityDetailType, string> = {
  [EntityDetailType.twitter]: "https://twitter.com/lastcontrarian",
  [EntityDetailType.github]: "https://github.com/vbuterin",
  [EntityDetailType.discord]: "vitalik.eth#1036",
  [EntityDetailType.linkedin]: "https://www.linkedin.com/in/balajissrinivasan",
  [EntityDetailType.website]: "https://my-site.com",
  [EntityDetailType.location]: "Lisbon, Portugal",
};

export const iconByType: Record<EntityDetailType, JSX.Element> = {
  [EntityDetailType.twitter]: <TwitterOutlined />,
  [EntityDetailType.github]: <GithubOutlined />,
  [EntityDetailType.discord]: <DiscordIcon />,
  [EntityDetailType.linkedin]: <LinkedinFilled />,
  [EntityDetailType.website]: <LinkOutlined />,
  [EntityDetailType.location]: <EnvironmentOutlined />,
};

interface EntityDetailsProps {
  entityDetail: EntityDetail;
  copyToClipboard?: boolean;
}

export const EntityDetailAvatar: FC<EntityDetailsProps> = ({
  entityDetail,
  copyToClipboard = false,
}) => {
  const handleCopyToClipBoard = useCopyToClipboardAndShowToast();

  if (copyToClipboard) {
    return (
      <span
        className="hover:cursor-pointer"
        onClick={() => handleCopyToClipBoard(entityDetail.value)}
      >
        <Avatar size="small">{iconByType[entityDetail.type]}</Avatar>
      </span>
    );
  }

  return (
    <a
      href={entityDetail.value}
      target="_blank"
      key={entityDetail.id}
      // @ts-expect-error
      name="Open entity detail"
      rel="noreferrer"
    >
      <Avatar size="small">{iconByType[entityDetail.type]}</Avatar>
    </a>
  );
};
