import { Button, Dropdown, Menu } from "antd";
import React, { FC, useCallback } from "react";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { useToggle } from "@dewo/app/util/hooks";
import {
  WorkspaceSection,
  WorkspaceSectionLayout,
} from "@dewo/app/graphql/types";
import { RenameWorkspaceSectionPopover } from "../../../workspace/section/RenameWorkspaceSectionPopover";
import { useUpdateWorkspaceSection } from "@dewo/app/containers/workspace/section/hooks";
import { CreateWorkspaceModal } from "@dewo/app/containers/workspace/create/CreateWorkspaceModal";
import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

interface Props {
  section: WorkspaceSection;
  organizationId: string;
}

export const WorkspaceSectionOptionsButton: FC<Props> = ({
  section,
  organizationId,
}) => {
  const canCreateWorkspace = usePermission("create", {
    __typename: "Workspace",
    organizationId,
  });

  const createModal = useToggle();

  const updateSection = useUpdateWorkspaceSection();
  const handleDeleteSection = useCallback(
    () =>
      updateSection({
        id: section.id,
        organizationId,
        deletedAt: new Date().toISOString(),
      }),
    [updateSection, section.id, organizationId]
  );

  const renameWorkspace = useToggle();
  const otherLayout =
    section.layout === WorkspaceSectionLayout.GRID
      ? WorkspaceSectionLayout.LIST
      : WorkspaceSectionLayout.GRID;

  return (
    <>
      <Dropdown
        trigger={["click"]}
        placement="bottomLeft"
        overlay={
          <Menu>
            {canCreateWorkspace && (
              <Menu.Item icon={<PlusOutlined />} onClick={createModal.toggleOn}>
                Create Space
              </Menu.Item>
            )}
            <Menu.Item
              icon={
                otherLayout === WorkspaceSectionLayout.LIST ? (
                  <UnorderedListOutlined />
                ) : (
                  <AppstoreOutlined />
                )
              }
              onClick={() =>
                updateSection({
                  id: section.id,
                  organizationId,
                  layout: otherLayout,
                })
              }
            >
              Change layout
            </Menu.Item>
            <Menu.Item
              icon={<EditOutlined />}
              onClick={renameWorkspace.toggleOn}
            >
              Rename this section
            </Menu.Item>
            <Menu.Item
              danger
              icon={<DeleteOutlined />}
              onClick={handleDeleteSection}
            >
              Remove this section
            </Menu.Item>
          </Menu>
        }
      >
        <Button type="text" size="small" icon={<MoreOutlined />} />
      </Dropdown>

      <RenameWorkspaceSectionPopover
        visible={renameWorkspace.isOn}
        onClose={renameWorkspace.toggleOff}
        organizationId={organizationId}
        section={section}
      />

      <CreateWorkspaceModal
        organizationId={organizationId}
        visible={createModal.isOn}
        initialValues={{ sectionId: section.id }}
        onClose={createModal.toggleOff}
      />
    </>
  );
};
