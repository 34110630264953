import { NextSeo } from "next-seo";
import React, { FC } from "react";
import { useOrganizationFromRouterQuery } from "../organization/hooks";
import { createSEOImageUrl } from "./util";

export const OrganizationSeo: FC = () => {
  const organization = useOrganizationFromRouterQuery();
  if (!organization) return null;

  const title = `${organization.name} | Dework`;
  const description =
    `Find and contribute to tasks and bounties from ${organization.name} here`.trim();
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: "Dework",
        images: [
          {
            url: createSEOImageUrl({
              title: organization.name,
              subtitle: description,
              imageUrl: organization.imageUrl,
            }),
          },
        ],
      }}
      twitter={{ site: "@deworkxyz", cardType: "summary_large_image" }}
    />
  );
};
