import { useNavigateToTask } from "@dewo/app/util/navigation";
import { Avatar, List, Row, Typography } from "antd";
import React, { FC, ReactNode, useCallback } from "react";
import {
  Task,
  TaskPriority,
  TaskStatus,
  TaskViewField,
} from "@dewo/app/graphql/types";
import { TaskGatingIcon } from "../card/TaskGatingIcon";
import { TaskTagsRow } from "../card/TaskTagsRow";
import { TaskActionButton } from "../actions/TaskActionButton";
import { UserAvatar } from "@dewo/app/components/avatars/UserAvatar";
import styles from "./TaskListItem.module.less";
import { usePrefetchTaskDetailsOnHover } from "../card/usePrefetchTaskDetailsOnHover";
import { NumberOutlined, UserAddOutlined } from "@ant-design/icons";
import { useTaskViewFields } from "../views/TaskViewFieldsContext";
import moment from "moment";
import { useUpdateTask } from "../hooks";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { SubtaskList } from "./SubtaskList";
import { useSubtasksExpanded } from "@dewo/app/contexts/SubtasksExpandedContext";
import { TaskStatusDropdown } from "@dewo/app/components/form/TaskStatusDropdown";
import { TaskPriorityIcon } from "@dewo/app/components/icons/task/TaskPriority";
import { DropdownSelect } from "@dewo/app/components/form/DropdownSelect";
import { PRIORITY_LABEL } from "@dewo/app/containers/task/board/util";

interface Props {
  task: Task;
  extra?: ReactNode;
  recalculateRowHeight?: () => void;
}

export const TaskListItem: FC<Props> = ({
  task,
  extra,
  recalculateRowHeight,
}) => {
  const fields = useTaskViewFields();
  const navigateToTask = useNavigateToTask(task.id);
  const prefetchTaskDetailsOnHover = usePrefetchTaskDetailsOnHover(task.id);

  const updateTask = useUpdateTask();
  const updateStatus = useCallback(
    (status: TaskStatus) => updateTask({ id: task.id, status }, task),
    [updateTask, task]
  );
  const canChange = usePermission("update", task, "status");
  const canChangePriority = usePermission("update", task, "priority");

  const subtasks = useSubtasksExpanded(task.id);
  const toggleSubtasks = subtasks.toggle;
  const handleToggleSubtasks = useCallback(() => {
    toggleSubtasks();
    recalculateRowHeight?.();
  }, [toggleSubtasks, recalculateRowHeight]);

  return (
    <List.Item
      className={styles.listItem}
      onClick={navigateToTask}
      {...prefetchTaskDetailsOnHover}
    >
      <Row align="middle" style={{ width: "100%", columnGap: 16 }}>
        {fields.has(TaskViewField.priority) && (
          <DropdownSelect
            key="priority"
            mode="default"
            disabled={!canChangePriority}
            onChange={(priority: TaskPriority) =>
              updateTask({ id: task.id, priority }, task)
            }
            options={(Object.keys(PRIORITY_LABEL) as TaskPriority[]).map(
              (priority) => ({
                value: priority,
                label: (
                  <Row align="middle" style={{ columnGap: 8 }}>
                    <TaskPriorityIcon priority={priority} size={13} />
                    {PRIORITY_LABEL[priority]}
                  </Row>
                ),
              })
            )}
          >
            <div>
              <TaskPriorityIcon priority={task.priority} size={13} />
            </div>
          </DropdownSelect>
        )}
        {fields.has(TaskViewField.status) && (
          <TaskStatusDropdown
            status={task.status}
            disabled={!canChange}
            onChange={updateStatus}
          />
        )}
        {fields.has(TaskViewField.gating) && <TaskGatingIcon task={task} />}
        {fields.has(TaskViewField.number) && (
          <Typography.Text
            type="secondary"
            style={{ width: 40 }}
            className="ant-typography-caption"
          >
            <NumberOutlined style={{ opacity: 0.3 }} />
            {task.number}
          </Typography.Text>
        )}
        {fields.has(TaskViewField.name) && (
          <Typography.Text
            className="font-semibold"
            // ellipsis
            style={{ flex: 1, wordBreak: "break-word" }}
          >
            {task.name}
            {!!task?.parentTask && (
              <>
                <Typography.Text type="secondary">
                  {"\xa0»\xa0"}
                  {task.parentTask.name}
                </Typography.Text>
              </>
            )}
          </Typography.Text>
        )}
        <TaskTagsRow
          task={task}
          fields={fields}
          style={{ flex: 1, justifyContent: "flex-end" }}
          expanded={subtasks.expanded}
          onToggleSubtasks={handleToggleSubtasks}
        />
        {fields.has(TaskViewField.assignees) && (
          <Row justify="end" style={{ width: 44 }}>
            <Avatar.Group
              maxCount={task.assignees.length === 3 ? 3 : 2}
              size={20}
            >
              {task.assignees.map((user) => (
                <UserAvatar key={user.id} user={user} linkToProfile />
              ))}
              {!task.assignees.length && (
                <Avatar size={20} icon={<UserAddOutlined />} />
              )}
            </Avatar.Group>
          </Row>
        )}
        {fields.has(TaskViewField.owners) && (
          <Row justify="end" style={{ width: 44 }}>
            <Avatar.Group maxCount={task.owners.length === 3 ? 3 : 2} size={20}>
              {task.owners.map((user) => (
                <UserAvatar key={user.id} user={user} linkToProfile />
              ))}
              {!task.owners.length && (
                <Avatar size={20} icon={<UserAddOutlined />} />
              )}
            </Avatar.Group>
          </Row>
        )}
        {fields.has(TaskViewField.creator) && !!task.creator && (
          <UserAvatar user={task.creator} size={20} linkToProfile />
        )}
        {fields.has(TaskViewField.createdAt) && (
          <Typography.Text
            type="secondary"
            className="ant-typography-caption"
            style={{ width: 80 }}
          >
            {moment(task.createdAt).fromNow()}
          </Typography.Text>
        )}
        {fields.has(TaskViewField.doneAt) && !!task.doneAt && (
          <Typography.Text
            type="secondary"
            className="ant-typography-caption"
            style={{ width: 80 }}
          >
            {moment(task.doneAt).fromNow()}
          </Typography.Text>
        )}
        {fields.has(TaskViewField.button) && (
          <Row justify="center" style={{ minWidth: 140 }}>
            <TaskActionButton task={task} />
          </Row>
        )}
        {extra}
      </Row>

      {!!task.subtasks.length && subtasks.expanded && (
        <SubtaskList
          subtasks={task.subtasks}
          style={{ marginTop: 8 }}
          showBranches
        />
      )}
    </List.Item>
  );
};
